<template>
  <div class="background">
    <br />
    <div class="center">
      <img class="responsive-logo" src="/logo_IHK_x_NEW.png" />
    </div>
    <br />
    <h2 style="color: #fff">Ausbildung macht mehr aus uns</h2>
    <br />

    <div
      class="center_and_50"
      v-for="e in links"
      :key="e.url"
      v-on:click="myhref(e.url)"
    >
      <a class="link-text" :href="e.url">{{ e.bezeichnung }}</a>
      <!--<v-spacer></v-spacer>-->
      <v-icon color="#b40055"> mdi-arrow-right </v-icon>
    </div>

    <br /><br />
  </div>
</template>

<script>
import {
  collections,
  getDocs,
  query,
  orderBy,
  db,
  collection,
} from "@/firebase/firebase.js";

export default {
  name: "Links",

  data: function () {
    return {
      Personen: [
        { id: 0, Name: "Christoph", Alter: 30 },
        { id: 1, Name: "Dennis", Alter: 50 },
      ],
      links: [],
    };
  },
  methods: {
    getName(Person) {
      return Person.Name;
    },
    myhref(web) {
      window.location.href = web;
    },
    async loadLinks() {
      let linkCollection = collections.get("links");
      let q = query(linkCollection, orderBy("sort", "asc"));
      let links = await getDocs(q);

      links.forEach((doc) => {
        let _data = { ...doc.data() };
        _data.docId = doc.id;
        this.links.push(_data);
      });
    },
  },
  computed: {
    summeAlter: function () {
      let summeAlter = 0;
      this.Personen.forEach((Person) => {
        summeAlter += parseInt(Person.Alter);
      });
      return summeAlter;
    },
  },
  mounted: async function () {
    await this.loadLinks();
  },
};
</script>

<style>
.center_and_50 {
  margin: auto;
  width: 380px;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
}

a.link-text {
  color: #b40055;
  font-weight: bold;
}

.background {
  width: 100%;
  text-align: center;
  height: 100%;
  overflow: auto;
  /*background: linear-gradient(to right, #9c124e, #931d73);*/
  background: linear-gradient(
    150deg,
    rgba(0, 165, 207, 1) 0%,
    rgba(64, 45, 124, 1) 75%,
    rgba(254, 203, 19, 1) 100%
  );
}

.responsive-logo {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .center_and_50 {
    width: 80%;
  }
}
</style>
