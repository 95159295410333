import Vue from 'vue'
import Router from 'vue-router'

import Links from '../views/Links.vue'

Vue.use(Router)
function getComponent(name) {
    return () => import('../views/' + name + '.vue')
}
const routes = [
    {
        path: '/',
        name: 'Default',
        component: Links,
        meta: { requiresAuth: true, showInDrawer: false },
    },
    {
        path: '/links',
        name: 'Links',
        component: getComponent("Links"),
        meta: { requiresAuth: false, showInDrawer: true },
    },
    {
        path: '/admin',
        name: 'Admin',
        component: getComponent("Admin"),
        meta: { requiresAuth: false, showInDrawer: true },
    },
];
export default new Router({
    routes: routes,
    mode: "history",
})
