import {
    initializeApp
} from '@firebase/app';
import {
    getAnalytics
} from "@firebase/analytics";
import {
    getStorage,
    ref,
    getDownloadURL,
    uploadBytes
} from "@firebase/storage";
import {
    getFirestore,
    collection,
    doc,
    getDocs,
    setDoc,
    deleteDoc,
    connectFirestoreEmulator,
    query,
    orderBy
} from '@firebase/firestore';
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithRedirect,
    getRedirectResult
} from "@firebase/auth";
const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
};


const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
//const analytics = getAnalytics(firebaseApp);
//const storage = getStorage(firebaseApp);
//const authProvider = new GoogleAuthProvider();
//connectFirestoreEmulator(db, "localhost", 8081);
const collections = new Map();
collections.set("links", collection(db, "links"));
const auth = getAuth();
auth.languageCode = "de";
export {
    firebaseApp,
    db,
    doc,
    getDocs,
    setDoc,
    deleteDoc,
    //    analytics,
    collection,
    collections,
    //    storage,
    ref,
    getDownloadURL,
    //    getAuth,
    //    signInWithPopup,
    //    signInWithRedirect,
    //   authProvider,
    //   GoogleAuthProvider,
    getRedirectResult,
    //    auth,
    query,
    orderBy,
    uploadBytes
} 
